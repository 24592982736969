<template>
  <div class="payment" v-if="isShow">
    <div class="payment-title">订单编号 {{order.id}}</div>
    <div class="payment-price">{{formatPrice}}</div>
    <div class="payment-btn-box">
      <van-button type="primary" round block @click="payment">去支付</van-button>
    </div>
    <div class="payment-remark"></div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'
import { Toast, Dialog } from 'vant'

export default {
  data() {
    return {
      isShow: false,
      order: {}
    }
  },
  
  created() {
    var params = this.$route.params
    console.log(params)
    wx.miniProgram.navigateTo({
      url: '/pages/payment/index?orderId=' + params.orderId
    })
    return
    if (params.orderId) {
      this.axios
        .post('/api/mall/loadOrderDetail', {id: params.orderId})
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.order = res.data.data.detail
            this.isShow = true
          } else {
            Toast('当前订单不存在！')
            this.$router.back()
          }
        })
    } else {
      Toast('订单参数错误！')
      this.$router.back()
    }
  },

  computed: {
    formatPrice() {
      return '¥' + (this.order.payAmount / 100).toFixed(2)
    }
  },

  methods: {
    payment() {
      // console.log("payment", wx)
      wx.miniProgram.navigateTo({
        url: '/pages/payment/index?orderId=' +this.order.id
      })
    }
  }
}
</script>

<style lang="less">
.payment {
  text-align: center;
  padding: 0 2rem;
  
  &-title {
    margin: 3rem 0;
  }
  
  &-price {
    font-size: 1.6em;
    font-weight: bold;
  }
  
  &-btn-box {
    margin-top: 3rem;
  }
  
  &-remark {
    position: fixed;
    width: 80%;
    bottom: 1rem;
    font-size: .8em;
  }
}
</style>